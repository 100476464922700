<template>
  <div>
    <el-form ref="form" :model="form" inline label-width="0" size="small">
      <el-form-item prop="appName">
        <el-input
          v-model="form.appName"
          :placeholder="$t('order.system') + $t('title.Name')"
          clearable
        />
      </el-form-item>
      <el-form-item prop="exportType">
        <el-input
          v-model="form.exportType"
          :placeholder="$t('page.export') + $t('title.types')"
          clearable
        />
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          v-model="form.status"
          :placeholder="$t('page.export') + $t('page.status')"
          clearable
          filterable
        >
          <el-option
            v-for="item in exportStatusList"
            :key="item.code"
            :label="item.msg"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      v-loading="queryLoading"
      :data="tableData"
      :max-height="maxHeight"
      border
      size="small"
      stripe
    >
      <el-table-column :label="$t('order.system') + $t('title.Name')" align="center" prop="appName" />
      <el-table-column :label="$t('page.export') + $t('title.types')" align="center" prop="exportType" />
      <el-table-column :label="$t('export.exportDescription')" align="center" prop="exportDesc" />
      <el-table-column :label="$t('page.export') + $t('page.status')" align="center" prop="status" />
      <el-table-column :label="$t('topic.Reasonforfailure')" align="center" prop="failReason" />
      <el-table-column :label="$t('page.createTime')" align="center" prop="createTime" />
      <el-table-column :label="$t('page.operate')" align="center">
        <template #default="{ row }">
          <el-button v-if="row.status === '处理完成'" size="small" type="text" @click="handleDownload(row)">
            {{ $t('export.download') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging ref="pager" v-loading="queryLoading" :pager="pager" end @update="handlePagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { download, getExportList } from './api'
import { getExportEnums } from '../api'
import { debounceGetTableMaxHeight } from '@/utils'

export default {
  name: 'ExportList',
  components: { Paging },
  data() {
    return {
      form: {
        appName: '',
        exportType: '',
        status: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      tableData: [],
      maxHeight: 0,
      exportStatusList: []
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        current: this.pager.current,
        size: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryedChange = true
      },
      deep: true
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
    this.getExportEnums()
    this.handleQuery()
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getExportEnums() {
      const { datas } = await getExportEnums()
      this.exportStatusList = datas?.exportStatusList || []
    },
    handleReset() {
      this.form = {
        appName: '',
        exportType: '',
        status: ''
      }
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.queryedChange) {
        this.pager.current = 1
      }
      const { datas } = await getExportList(this.queryParams).finally(() => {
        this.queryLoading = false
        this.queryedChange = false
      })
      this.tableData = datas?.list || []
      this.pager.total = datas?.totalCount || 0
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    async handleDownload(row) {
      const { downloadAddress } = row
      if (downloadAddress) {
        const { datas } = await download({ downloadAddress })
        if (datas) {
          window.open(datas, '_blank')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
