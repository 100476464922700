import request from '@/utils/oms-request'

export const getExportList = (params) => {
  return request({
    url: 'oms-export/export-info/page',
    params
  })
}

export const download = (params) => {
  return request({
    url: 'oms-export/execute-export/getDownloadUrl',
    params
  })
}
